.resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 5px;
    right: 0;
    top: 0;
    z-index: 999999;
    border-right: 6px solid rgba(215, 226, 255, 0.5);
  }
  
  .resize-handle:hover {
    border-color: #ccc;
  }
  
  .resize-handle.active {
    border-color: #4db89e;
  }
  
  .myCheckbox {
    width: 25px !important;
  }
  
  .sticky {
    position: sticky;
    top: 0;
    animation: 0.2s ease;
    background: white;
    z-index: 888;
  }
  
  .requestLogin {
    display: none !important;
  }
  
  #dropdown {
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
  }
#salesPageTable thead tr  th:first-child {
    border-left: none ;
    justify-content: center ;
    border-right: 6px solid rgba(215, 226, 255, 0.5);
    /*padding-left: 22px;*/
}
#salesPageTable thead tr th{
    user-select: none;
}
#salesPageTable thead tr .sticky-element {
    border-left: none;
    border-right: none ;
    border-top: 1px solid #D9D9D9 ;
    border-bottom: 1px solid #D9D9D9;
    color: #1E3166;
    font-weight: 600;
    height: 85px;

    @media (min-width: 769px) and (max-width: 1440px) {
        height: 70px;
    }
}
#salesPageTable{
    border-bottom: 1px solid #D9D9D9;
}


#salesPageTable thead tr .sticky-element.sticky-checkbox-column {
    left: 0;
    z-index: 995;
}
#salesPageTable thead tr .sticky-element .th-inner-container{
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
#salesPageTable thead tr .sticky-element#sticky-image-col{
    position: sticky;
    left: 70px;
    z-index: 995;
}
#salesPageTable thead tr .sticky-element .th-inner-container .lock-icon{
    height: 18px;
    width: 20px;
}
@media only screen and (max-width: 768px){
    #salesPageTable thead tr .sticky-element .th-inner-container .lock-icon{
       display: none;
    }
}

#salesPageTable thead tr .sticky-element .th-inner-container .lock-icon svg{
    cursor: pointer;
    height: 20px !important;
    width: 20px !important;
    transform: none !important;
    margin: 0 !important;
}
.hiddenElementForTippyStickyColumnsSales{
    visibility: hidden;
    position: absolute;
    top: -9999px;
}
.hiddenElementForTippyStickyColumnsSales .tippy-box{
    background-color: #fff;
    border: 1px solid #cecece;
    font-weight: normal;
    color: #333;
    z-index: 9999 !important;
}
.hiddenElementForTippyStickyColumnsSales .tippy-content{
    color: #333 !important;
}
#salesPageTable thead tr .sticky-element .th-inner-container .lock-icon svg path {
    fill: rgba(30, 49, 102, 0.5);
}
#salesPageTable thead tr .sticky-element .th-inner-container .lock-icon.sticky-column-active-sales svg path{
    fill: #1E3166;
}

#salesPageTable .stickyColumnActive{
    position: sticky;
    left: 70px;
    z-index: 9;
    /*background-color: #f8f8f8;*/
}
#salesPageTable .stickyCheckboxColumn{
    position: sticky;
    left: 0;
    z-index: 9;
    /*background-color: #f8f8f8;*/
}

/*#salesPageTable table tbody tr:nth-child(odd) td.stickyColumnActive {*/
/*    background-color: #fff;*/
/*}*/
/*#salesPageTable table tbody tr:nth-child(odd) td.stickyCheckboxColumn {*/
/*    background-color: #fff;*/
/*}*/

#salesPageTable .popoverIcon{
    height: 20px;
    width: 20px;
}

@media (min-width: 769px) and (max-width: 1440px) {
    #salesPageTable .popoverIcon{
        height: 15px;
        width: 15px;
    }
}

.sticky-element span p{
    color: #1E3166;
}

.socialIcon svg {
    width: 100%;
    height: 100%;
}

.markSoldButton {
    padding: 10px;
    background:#FF4D4D;
}

.markSoldButtonDisabled {
    background:#B6B6B6 !important;
    border: none !important;

}
